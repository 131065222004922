<template>
  <RydTopBar :title="$t('cars.page.title')" backBtnVisible @backButtonClick="redirectToApp()" />
  <RydLoadingOverlay v-if="!componentReady" />
  <!-- Car list -->
  <div v-else class="view-block pt-6 px-4">
    <section>
      <h2 class="fs-20 text-left mb-1 font-bold">
        {{ $t('cars.title') }}
      </h2>
      <p class="fs-16">
        {{ $t('cars.subtitle') }}
      </p>
      <ul class="pt-4 m-0">
        <template v-for="car in sortedCars" :key="car.thingId">
          <li class="list-none h-9 flex justify-center flex-col my-3">
            <router-link :to="`/cars/${car.thingId}`" class="flex justify-between items-center w-full">
              <div>
                <span class="fs-15 font-medium"> {{ car.nickName }}</span>
                <p
                  v-if="car.ymme?.licensePlate"
                  class="text-fs-11 pr-4"
                >
                  {{ $t('cars.list.license_plate') }}: {{ car.ymme?.licensePlate }}
                </p>
              </div>
              <div class="flex items-center">
                <div v-if="activeCarId === car.thingId" 
                     class="bg-ryd-primary rounded-xl text-white px-4 py-0.5 mr-2 font-medium fs-15"
                >
                  {{ $t('cars.list.status') }}
                </div>
                <RydIcon icon="chevron-right" />
              </div>
            </router-link>
          </li>
          <RydDivider class="mt-1 w-full" />
        </template>
      </ul>
    </section>
    <RydButton class="w-full my-6" href="/cars/new">
      {{ $t('cars.button.action1') }}
    </RydButton>
  </div>
  <div v-if="errorAPI" class="mt-6 mb-8">
    {{ $t('errors.car.get_cars') }}
  </div>
</template>

<script setup lang="ts">
import RydButton from '@/components/RydButton/RydButton.vue';
import RydIcon from '@/components/RydIcon/RydIcon.vue';
import RydLoadingOverlay from '@/components/RydLoading/RydLoadingOverlay.vue';
import RydDivider from '@/components/RydDivider.vue';
import RydTopBar from '@/components/RydTopbar/RydTopBar.vue';

import { checkAndApplyTheme, removeTheme } from '@/utils/utils';

import { useAuthStore } from '@/store/auth/auth';
import { computed, onMounted, ref, onBeforeMount, onBeforeUnmount } from 'vue';
import { useRoute } from 'vue-router';

const authState = useAuthStore();
const route = useRoute();

const errorAPI = ref(false);

const componentReady = computed(() => !authState.isLoading);
onBeforeMount(() => {
  checkAndApplyTheme();
});
onBeforeUnmount(() => {
  removeTheme();
});

// sort all cars by nickName
const sortByNickname = computed(() => {
  // shallow copy for avoiding side effect inside a computed
  const sortByNickname = [...authState.userPreferences];

  sortByNickname.sort((a, b) => a.nickName.localeCompare(b.nickName));
  return sortByNickname;
});
const sortedCars = computed(() => {
  const preferredCar = sortByNickname.value.find((car) => car.thingId === activeCarId.value);
  if (preferredCar) {
    return [preferredCar, ...sortByNickname.value.filter((car) => car.thingId !== activeCarId.value)];
  }
  return sortByNickname.value;
});
const activeCarId = computed(() => authState.preferredVehicle?.thingId);

const redirectToApp = () => {
  const urlCallbackObject = new URL('webViewEvent://profileCarsBack');
  window.location.replace(urlCallbackObject.toString());
};

</script>
