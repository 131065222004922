import { defineStore } from 'pinia';

import {
  getAllHistory,
  getAllHistoryWash,
  getEvent,
  createEventCostAPI,
  updateEventCostAPI,
  deleteEventCostAPI,
  uploadImageAPI,
  getTripEventsFromTo,
  unmergeEvent,
  getTransactionsOverviewAPI
} from '@/api/events';
import { EnrichedEventInfoTripAPI, EnrichedPaymentAPI, EnrichedCostAPI } from '@/api/types';
import {
  HistoryStateTypes,
  HistoryTripEvent,
  HistoryPaymentEvent,
  EventFilters,
  HistoryCostEvent,
  HistoryCreateCostPayload,
  Transactions
} from './types';
import { eventsMap, getStartEnd, getCostAttributesMap, parseTripEvents } from './historyUtils';
import { useAuthStore } from '@/store/auth/auth';

export const DEFAULT_FILTER = {
  type: 'ALL',
  productTypes: '',
  icon: 'history/ryd',
  text: 'all',
  visible: true
};
export const ALL_VEHICLES_FILTER = {
  thingId: 'ALL',
  carOdometerM: 0,
  isArchived: false
};

export const useHistoryStore = defineStore('history', {
  state: (): HistoryStateTypes => ({
    ui: { loading: false },
    events: [],
    washEvents: [],
    eventsPage: 1,
    washEventsPage: 1,
    event: null,
    eventSelectedFilter: DEFAULT_FILTER,
    error: null,
    transactions: {
      yearly: null,
      semiannually: null,
      monthly: null
    },
    mergeEvents: {
      eventsBefore: [],
      eventsAfter: []
    },
    thingIdToShowEvents: 'ALL'

  }),
  actions: {
    async getEventsOlder(to: string) {
      try {
        const { items } = await getTripEventsFromTo('', to, this.thingIdToShowEvents);
        const historyEvents = parseTripEvents(items);

        this.mergeEvents.eventsAfter?.push(...historyEvents);
      } catch (e) {
        throw e;

      }
    },
    async getEventsNewer(from: string) {
      try {
        const { items } = await getTripEventsFromTo(from, '', this.thingIdToShowEvents);
        const historyEvents = parseTripEvents(items);
        this.mergeEvents.eventsBefore?.push(...historyEvents);
      } catch (e) {
        throw e;
      }
    },
    resetMergeEvents() {
      this.mergeEvents.eventsBefore = [];
      this.mergeEvents.eventsAfter = [];
    },
    async getHistoryEvents(page: number, refresh = false, thingId: string | null = null) {
      const authState = useAuthStore();
      const getThingId = thingId ? thingId : this.thingIdToShowEvents || authState.preferredVehicle?.thingId;


      const filter = this.eventSelectedFilter.type === 'ALL'
        ? null
        : this.eventSelectedFilter;

      if (refresh) { this.events = []; }

      try {
        const { items } = await getAllHistory(page, filter?.type, getThingId, filter?.productTypes, filter?.triggerEventTypeAtSource);

        // Needed for stop the infinite scroll
        if (!items.length) { return []; }

        const historyEvents = items.reduce(
          (acc, event): HistoryTripEvent[] | HistoryPaymentEvent[] | HistoryCostEvent[] => {
            if (event.type === eventsMap['TRIP']) {
              const info = event.information.trip;

              const parsedEvent: HistoryTripEvent = {
                ...event,
                distanceM: info.distanceM,
                drivingScore: info.drivingScore,
                information: getStartEnd(info),
                isResultOfMerge: info.isResultOfMerge
              };

              acc.push(parsedEvent);
            }
            if (event.type === eventsMap['PAYMENT']) {
              const info = event.information.payment;
              const parsedEvent: HistoryPaymentEvent = {
                ...event,
                information: info
              };
              acc.push(parsedEvent);
            }
            if (event.type === eventsMap['COST']) {
              const info = event.information.cost;
              const parsedEvent: HistoryCostEvent = {
                ...event,
                information: {
                  ...info,
                }
              };
              acc.push(parsedEvent);
            }
            return acc;
          }, []);

        this.events?.push(...historyEvents);
        return historyEvents;

      } catch (error) {
        this.events = [];
        throw error;
      }
    },
    async getHistoryEventsWash(page: number, refresh = false, retry = 0) {
      const authStore = useAuthStore();

      if (refresh) { this.washEvents = []; }
      try {
        const { items } = await getAllHistoryWash(page);
        // Needed for stop the infinite scroll
        if (!items.length) { return []; }

        const historyEvents = items.reduce(
          (acc, event): HistoryCostEvent[] => {
            const info = event.information.cost;
            const parsedEvent: HistoryCostEvent = {
              ...event,
              information: info
            };
            acc.push(parsedEvent);
            return acc;
          }, []);

        this.washEvents?.push(...historyEvents);
        return historyEvents;
      } catch (error: any) {
        if (error.response?.status === 403 && retry === 0) {
          console.log(retry);

          try {
            await authStore.storeUserPreferences();
            await authStore.setPreferredVehicle();
            await this.getHistoryEventsWash(1, false, 1);
          } catch (error) {
            console.log('Get wash tickets error', error);
            throw error;
          }
          return;
        }
        this.washEvents = [];
        throw error;
      }
    },
    async getEventById(id: string) {
      try {
        const response = await getEvent(id);

        if (response.type === eventsMap['TRIP']) {
          const info = response.information.trip as EnrichedEventInfoTripAPI;

          this.event = {
            ...response,
            distanceM: info.distanceM,
            drivingScore: info.drivingScore,
            information: getStartEnd(info),
            timeliness: info.timeliness,
            isResultOfMerge: info.isResultOfMerge
          };
        }
        if (response.type === eventsMap['PAYMENT']) {
          const info = response.information.payment as EnrichedPaymentAPI;
          this.event = {
            ...response,
            information: info
          };
        }
        if (response.type === eventsMap['COST']) {
          const info = response.information.cost as EnrichedCostAPI;

          this.event = {
            ...response,
            information: {
              ...info,
            }
          };
        }
      } catch (error) {
        this.event = null;
        throw error;
      }
    },
    setEventFilter(event: EventFilters) {
      this.eventSelectedFilter = event;
    },
    async createEventCost(
      payload: HistoryCreateCostPayload,
      images: {
        imageBuffer: ArrayBuffer;
        type: string;
      }[]
    ) {
      const parsePayload = {
        ...getCostAttributesMap(payload),
        thingId: this.thingIdToShowEvents
      };
      try {
        const response = await createEventCostAPI(parsePayload);
        if (images.length && response) {
          images.map(async (image) => await this.uploadImage(image.imageBuffer, image.type, response.id));
        }
        await this.getHistoryEvents(1, true, this.thingIdToShowEvents);
        this.eventsPage = 1;
      } catch (e) {
        throw e;
      }
    },
    async updateEventCost(payload: HistoryCreateCostPayload, id: string) {
      const parsePayload = getCostAttributesMap(payload);

      try {
        await updateEventCostAPI(parsePayload, id);
        await this.getHistoryEvents(1, true);
        this.eventsPage = 1;

      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    async deleteEventCost(category: string, id: string, type: string) {
      try {
        await deleteEventCostAPI(category, id, type);
        await this.getHistoryEvents(1, true);
        this.eventsPage = 1;

      } catch (error) {
        console.log(error);
        throw error;

      }
    },
    async uploadImage(image: ArrayBuffer, type: string, id: string = null) {
      const eventId = id ?? this.event.id;
      try {
        await uploadImageAPI(image, type, eventId);
      } catch (error) {
        console.log(error);

      }
    },
    async unmergeEvent(id: string) {
      try {
        await unmergeEvent(id);
        // we have to re-trigger the all history events
        await this.getHistoryEvents(1, true);
        this.eventsPage = 1;

      } catch (error) {
        console.log(error);
      }
    },

    async getTransactionsOverview(timeFrame: string, endDate: string, startDate: string) {

      if (this.transactions[timeFrame as keyof Transactions]) return;

      try {
        const response = await getTransactionsOverviewAPI(this.thingIdToShowEvents, endDate, startDate);
        this.transactions[timeFrame as keyof Transactions] = response;

      } catch (error) {
        console.log(error);
      }
    },
    cleanTransactions() {
      this.transactions.yearly = null;
      this.transactions.semiannually = null;
      this.transactions.monthly = null;
    }
  },
});
