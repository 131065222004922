import axios from './index';
import { PaginatedEvent, Event, CreateCostEventAPI } from './types';

export async function getAllHistory(
  page: number,
  filter: string | null,
  thingId: string,
  productType: string,
  triggerEventTypeAtSource: string
): Promise<PaginatedEvent> {
  const limit = 20;
  const types = !filter ? '' : `&types=${filter}`;
  const vehicleId = thingId && thingId !== 'ALL' ? `&vehicleId=${thingId}` : '';
  const checkProductType = productType ? `&productTypes=${productType}` : '';
  const manualEntries = triggerEventTypeAtSource === 'MANUAL' ? '&triggerEventTypeAtSource=MANUAL' : '';

  const response = await axios({
    method: 'get',
    url: `v4/account-history?page=${page}&limit=${limit}${types}${vehicleId}${checkProductType}${manualEntries}`,
  });
  return response.data;
}

export async function getTripEventsFromTo(from = '', to = '', thingId: string): Promise<PaginatedEvent> {
  const vehicleId = thingId ? `&vehicleId=${thingId}` : '';
  const getTo = !to ? '' : `&to=${to}`;
  const getFrom = !from ? '' : `&from=${from}`;

  const response = await axios({
    method: 'get',
    url: `v4/account-history?page=1&limit=40${getFrom}${getTo}&types=TRIP${vehicleId}`,
  });
  return response.data;
}

export async function getAllHistoryWash(page: number): Promise<PaginatedEvent> {
  const limit = 20;
  const response = await axios({
    method: 'get',
    url: `v4/account-history?page=${page}&limit=${limit}&costCategory=CAR_WASH`,
  });
  return response.data;
}

export async function getEvent(id: string): Promise<Event> {
  const response = await axios({
    method: 'get',
    url: `v4/account-history/${id}`
  });
  return response.data;
}
export const createEventCostAPI = async (payload: CreateCostEventAPI): Promise<Event> => {
  const response = await axios({
    method: 'post',
    url: `v4/account-history/costs`,
    data: {
      ...payload
    }
  });
  return response.data.data;
};
export const updateEventCostAPI = async (payload: CreateCostEventAPI, id: string) => {
  await axios({
    method: 'patch',
    url: `v4/account-history/costs/${id}`,
    data: {
      ...payload,
      reach: 'onlyThis'
    }
  });
};
export const deleteEventCostAPI = async (category: string, id: string, reach: string) => {
  await axios.delete(`v4/account-history/costs/${id}?category=${category}&reach=${reach}`);
};
export const uploadImageAPI = async (image: ArrayBuffer, type: string, eventId: string) => {
  await axios({
    method: 'POST',
    url: `v4/account-history/${eventId}/images`,
    headers: {
      'Content-Type': type
    },
    data: image
  });
};

export const getTransactionsOverviewAPI = async (thingId: string, endDate: string, startDate: string) => {
  const response = await axios.get(`statistics/things/${thingId}/expenses?endDate=${endDate}&startDate=${startDate}`);
  return response.data;
};

export const mergeEvents = async (eventIds: string[], thingId: string) => {
  const response = await axios({
    method: 'POST',
    url: `/events/merged-trip`,
    data: {
      eventIds,
      thingId
    }
  });
  return response.data.data;
};

export const unmergeEvent = async (id: string) => {
  await axios.delete(`/events/merged-trip/${id}`);
};

export const downloadInvoiceAPI = async (orderId: string) => {
  return await axios({
    url: `v4/invoices/${orderId}`,
    method: 'GET',
    responseType: 'blob',
  });

};

export const updateWashCode = async (redemptionCodeId: string) => {
  await axios({
    method: 'patch',
    url: `/v4/redemption-codes/${redemptionCodeId}`,
    data: {
      isRedeemed: true
    }
  });
};