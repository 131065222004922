<template>
  <TopBar 
    :title="$t('history.title-webview')"
    iconColor="text-white"
  />
  <Teleport v-if="historyState.events?.length && historyState.thingIdToShowEvents !== 'ALL'" to="#topBarIconRight">
    <div class="flex">
      <RPButton theme="custom" class="p-0 min-w-min" @click="$router.push('/history/transactions-overview')">
        <RPIcon type="graphs" svgFill="text" />
      </RPButton>
      <RPButton theme="custom" class="p-0 min-w-min" @click="isModalExportOpen = true">
        <RPIcon type="export" svgFill="text" />
      </RPButton>
    </div>
  </Teleport>

  <HistoryFilters @carChange="setCarFilter" @eventChange="setEventFilter" />

  <ModalBottom v-if="isModalExportOpen" @close="isModalExportOpen = false">
    <p class="text-left fs-16 font-semibold">
      {{ $t('history.export.title') }}
    </p>
    <p class="text-left my-4 ">
      {{ $t('history.export.description') }} {{ userEmail }}
    </p>
    <RPButton class="mb-6" @click="exportCSV">
      {{ $t('history.export.button.primary') }}
    </RPButton>
  </ModalBottom>
  <template v-if="componentReady">
    <div v-if="allEvents?.length" ref="historyRef" class="px-4 pb-10">
      <div v-for="event in allEvents" :key="event.id">
        <RPCard 
          hasClick
          hasTitle
          class="mb-4"
          :class="{ 'bg-primary-darker border-secondary-btn-bg': isEventUpcoming(event.sampleTime) }"
          @click="$router.push(`/history/${event.id}`)"
        >
          <template #title>
            {{ isEventUpcoming(event.sampleTime) ? $t('history.event_cost_upcoming') : $t(getCardName(event)) }}
          </template>
          <!-- <TripSummary 
            v-if="event.type === 'TRIP'"
            :event="(event as HistoryTripEvent)" 
            class="flex items-center pt-8 px-4 pb-2" 
            :isDistance="true"
          /> -->
          <PaymentSummary 
            v-if="event.type === 'PAYMENT'"
            :event="(event as HistoryPaymentEvent)"
            class="pt-6 px-4 pb-2" 
          />
          <CostSummary 
            v-if="event.type === 'COST'"
            :event="(event as HistoryCostEvent)"
            :isUpcoming="isEventUpcoming(event.sampleTime)"
            class="pt-8 px-4 pb-2" 
          />
        </RPCard>
      </div>
      <LoadingSpinner v-if="loadMore" />
    </div>
    <span v-else class="pt-[15px] text-center px-4">
      {{ $t('history.empty') }}
    </span>
  </template>
  <LoadingOverlay v-else />

  <RPButton
    v-if="!vehicleToShowEvents?.isArchived && historyState.thingIdToShowEvents !== 'ALL'"
    theme="custom"
    class="
      fixed top-[90vh] right-4 
      bg-primary-btn-bg rounded-full 
      w-12 h-12 p-0
      text-4xl text-secondary-icon font-thin"
    @click="$router.push('/history/create')"
  >
    +
  </RPButton>
</template>

<script setup lang="ts">
import TopBar from '@/components/TopBar.vue';
import RPCard from '@/components/RPCard/RPCard.vue';
import TripSummary from '@/components/TripSummary/TripSummary.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import RPButton from '@/components/RPButton/RPButton.vue';
import RPIcon from '@/components/RPIcon/RPIcon.vue';
import ModalBottom from '@/components/ModalBottom.vue';
import PaymentSummary from './components/PaymentSummary.vue';
import CostSummary from './components/CostSummary.vue';
import HistoryFilters from './components/HistoryFilters.vue';

import { HistoryTripEvent, HistoryPaymentEvent, HistoryCostEvent, EventFilters } from '@/store/history/types';
import { useHistoryStore, DEFAULT_FILTER } from '@/store/history/history';
import { useAuthStore } from '@/store/auth/auth';
import { computed, onMounted, ref, onBeforeMount } from 'vue';
import { debounce } from 'lodash';
import { exportEvents } from '@/api/user';


const historyState = useHistoryStore();
const authState = useAuthStore();

const componentReady = ref(false);
const historyRef = ref<HTMLElement | null>(null);

onBeforeMount(async () => {
  // no needs to reload the events
  if (!!historyState.events?.length) {
    componentReady.value = true;
    return;
  }

  try {
    await historyState.getHistoryEvents(1);
    historyState.eventsPage = 1;
  } catch (error) {
    console.log(error);
  } finally {
    componentReady.value = true;
  }
});

const getCardName = (event: HistoryTripEvent | HistoryPaymentEvent | HistoryCostEvent) => {
  if (event.type === 'PAYMENT') return 'history.event_payment';

  if (event.type === 'COST') {
    return (event as HistoryCostEvent).information.category === 'GIFT_CARD' ? 'history.event_cost_gift_card' : 'history.event_cost';
  }
};
// infinite scroll
const heightToRemoveFromScroll = 44 + 88; // 44 header, 88 filters
const loadMore = ref(false);
const endOfEvents = ref(false);

onMounted(() => {

  window.addEventListener('scroll', debounce(() => {
    if (historyRef.value && !endOfEvents.value) {
      checkScroll();
    }
  }, 1000));
});

const checkScroll = async () => {
  const scrollPos = document.documentElement.scrollTop - heightToRemoveFromScroll;
  const docHeight = historyRef.value.offsetHeight - window.innerHeight;
  const tolerance = 10;

  const bottomOfWindow = (scrollPos + tolerance) >= docHeight;

  if (bottomOfWindow && allEvents.value.length >= 20) {
    loadMore.value = true;
    historyState.eventsPage++;
    try {
      const newEvents = await historyState.getHistoryEvents(historyState.eventsPage);
      if (!newEvents.length) { endOfEvents.value = true; }

    } catch (error) {
      console.log(error);
    } finally {
      loadMore.value = false;
    }
  }
};

//Events to show in list
const allEvents = computed(() => historyState.events);

const setEventFilter = async (event: EventFilters) => {

  componentReady.value = false;
  // Reset the page counter
  historyState.eventsPage = 1;
  //Set the filter selected on the store
  historyState.setEventFilter(event);
  // Make a new API call with the filter selected
  try {
    await historyState.getHistoryEvents(historyState.eventsPage, true, historyState.thingIdToShowEvents);
  } catch (error) {
    console.log(error);
  } finally {
    componentReady.value = true;
  }
};

const setCarFilter = async (thingId: string) => {
  componentReady.value = false;
  // Reset the page counter
  historyState.eventsPage = 1;
  // Reset the events to all
  historyState.setEventFilter(DEFAULT_FILTER);

  // Reset the store event array
  historyState.events = [];
  // Make a new API call with the new car selected
  try {
    await historyState.getHistoryEvents(historyState.eventsPage, false, thingId);
  } catch (error) {
    console.log(error);
  } finally {
    componentReady.value = true;
  }
};
// check if event is in the future for cost events
const isEventUpcoming = (eventDate: string) => {
  const eventDateObject = new Date(eventDate);
  const now = new Date();
  return eventDateObject.getTime() > now.getTime();
};

const userEmail = authState.email;
const isModalExportOpen = ref(false);
const exportCSV = async () => {
  try {
    await exportEvents(historyState.thingIdToShowEvents);

  } catch (error) {
    console.log(error);
  } finally {
    isModalExportOpen.value = false;
  }
};
const vehicleToShowEvents = computed(() => authState.userPreferences.find(item => item.thingId === historyState.thingIdToShowEvents));

</script>
